var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mytasks_iframe_wrap" },
    [
      _vm.type == "上传原稿"
        ? _c("my-task-upload-new", { ref: "myTaskNew" })
        : _vm._e(),
      _vm.type == "上传完成稿"
        ? _c("my-task-submit-new", { ref: "myTaskNew" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }