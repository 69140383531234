<template>
    <div class="mytasks_iframe_wrap">
      <!-- <iframe
        :style="{ height: `${iframeHeight}px` }"
        class="mytasks_iframe"
        :src="`//${host}/mytasks/${params.taskId}/${params.productionId}/${params.chapterId}/uploadPage?record=${params.record}`"
        frameborder="0"
      ></iframe> -->
      <my-task-upload-new v-if="type=='上传原稿'" ref="myTaskNew"></my-task-upload-new>
      <my-task-submit-new v-if="type=='上传完成稿'" ref="myTaskNew"></my-task-submit-new>
    </div>
</template>

<script>
import { addListenerMessage } from '@/utils/util'
import MyTaskUploadNew from './modules/myTaskUploadNew.vue'
import MyTaskSubmitNew from './modules/MyTaskSubmitNew.vue'
export default {
  components: {
    MyTaskUploadNew,
    MyTaskSubmitNew
  },
  data() {
    addListenerMessage(this);
    return {
      params: {
        ...this.$route.params,
        record:this.$route.query.record,
      },
      iframeHeight: 0,
      host: this.VUE_APP_TRANSLATE_URL,
      type:''
    }
  },
  methods: {},
  created() {
    this.iframeHeight = window.innerHeight - 69
    this.type=this.$route.query.type
  }
}
</script>

<style lang="less">
.mytasks_iframe_wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.mytasks_iframe {
  width: 100%;
  // height: 100%;
  height: 500px;
  overflow: auto;
}
</style>
